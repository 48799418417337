import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import cn from "classnames";

import styles from "./mainSearch.module.css";
import { Button } from "../../components/common/button";
import { ReactComponent as SearchIcon } from "../../media/icons/search.svg";
import { ReactComponent as Arrow } from "../../media/icons/arrow-grey.svg";
import { ReactComponent as ArrowDown } from "../../media/icons/arrow-down.svg";
import { Modal } from "../../components/common/modal";
import { communitiesTypes } from "../../constants/searchResult";
import { actions } from "../../actions/search";
import { actions as landingSearch } from "../../actions/landing";
import CityStateZipInput from "../../components/landing/cityStateZipInput";
import { heritageUrls, heritageUrlTypes } from "../../constants/main";

const MainSearch = ({ className, communities: communitiesList }) => {
  const [activeCommunitiesModal, setActiveCommunitiesModal] = useState(false);
  const [activeCommunities, setActiveCommunities] = useState({});
  const [activeListingModal, setActiveListingModal] = useState(false);
  const { professions, practices } = useSelector(state => state.search);
  const dispatch = useDispatch();
  const getProfessions = useCallback(cityStateZip => dispatch(actions.getProfessions(cityStateZip)), [dispatch]);
  const getPractices = useCallback(cityStateZip => dispatch(actions.getPractices(cityStateZip)), [dispatch]);
  const getProfessionsTree = useCallback(() => dispatch(actions.getProfessionsTree()), [dispatch]);
  const [cityStateZipValue, setCityStateZipValue] = useState("");
  const [activeCityStateZip, setActiveCityStateZip] = useState({});
  const getCityStateZip = useCallback(cityStateZip => dispatch(landingSearch.getCityStateZip(cityStateZip)), [dispatch]);
  const { cityStateZipList } = useSelector(state => state.landing);
  const [activeProfession, setActiveProfession] = useState({});
  const [activePractices, setActivePractices] = useState({});
  const languageValue = activeCommunities?.communityType?.includes("language") ? activeCommunities?.heritage_title : "";

  const handleOpenCommunities = () => {
    setActiveCommunitiesModal(true);
  };

  const handleCloseCommunities = () => {
    setActiveCommunitiesModal(false);
  };

  const handleSelectCommunities = community => {
    setActiveCommunities(community);
    handleCloseCommunities();
  };

  const redirectUrl = useCallback(url => {
    if (window.location.host.includes(heritageUrlTypes.localhost)) {
      window.location.replace(`${heritageUrls.localhost}${url}`);
    } else if (window.location.host.includes(heritageUrlTypes.staging) || window.location.host.includes(heritageUrlTypes.qa)) {
      window.location.replace(`${heritageUrls.staging}${url}`);
    } else {
      window.location.replace(`${heritageUrls.production}${url}`);
    }
  }, []);

  const handleSubmit = () => {
    const state = activeCityStateZip.state || "";
    const city = activeCityStateZip.city || "";
    const country = activeCityStateZip.country_code?.toLowerCase() || "us";

    function buildUrl({ community, profession, specialty, language }) {
      const countryCode = country ? country.replaceAll(" ", "-") : "us";
      const communityCode = community || "all-communities";
      const stateCode = state ? state.replaceAll(" ", "-") : "";
      const cityCode = city ? city.replaceAll(" ", "-") : "";

      const locationParts = [countryCode, stateCode, cityCode]
        .filter(Boolean)
        .map(item => item.toLowerCase())
        .join("/");
      const otherParts = [profession, specialty, communityCode]
        .filter(Boolean)
        .map(item => item.toLowerCase())
        .join("-");
      const languageQuery = language ? `?language=${language}` : "";

      return `/${locationParts}-${otherParts}${languageQuery}`;
    }

    const mainUrl = buildUrl({
      community:
        activeCommunities.heritage_title?.includes("52") || activeCommunities.communityType?.includes("language")
          ? "all-communities"
          : activeCommunities.heritage_title,
      profession: activeProfession.title ? activeProfession.title?.toLowerCase() : "",
      specialty: activePractices.title ? activePractices.title.toLowerCase()?.replaceAll(" ", "-") : "",
      language: languageValue
    });

    // console.log("mainUrl", mainUrl);
    redirectUrl(mainUrl);
    // history.push(`/search-result?${filterParams}`);
  };

  const handleOpenProfessionModal = () => {
    setActiveListingModal(true);
  };

  const handleCloseProfessionModal = () => {
    setActiveListingModal(false);
  };

  useEffect(() => {
    getProfessions();
    getPractices();
    getProfessionsTree();
  }, []);

  const activeCategory = useMemo(() => {
    return activePractices.title || activeProfession.title || "Select Category";
  }, [activeProfession.title, activePractices.title]);

  return (
    <>
      <div className={cn(styles.main, className)}>
        <div className={styles.flex}>
          <div>
            <button type="button" className={styles.select} onClick={handleOpenCommunities}>
              {activeCommunities.heritage_title || "Select communities"}
              <Arrow />
            </button>

            <button type="button" className={styles.selectCategory} onClick={handleOpenProfessionModal}>
              {activeCategory}
              <ArrowDown />
            </button>
          </div>

          <div className={styles.inputWrap}>
            <CityStateZipInput
              className={styles.input}
              cityStateZipList={cityStateZipList}
              setCityStateZipValue={setCityStateZipValue}
              cityStateZipValue={cityStateZipValue}
              getCityStateZip={getCityStateZip}
              setActiveCityStateZip={setActiveCityStateZip}
              placeholder="Search location"
            />
            <SearchIcon className={styles.searchIcon} />
          </div>

          <Button className={styles.button} onClick={handleSubmit}>
            Search
          </Button>
        </div>
      </div>

      {activeCommunitiesModal && (
        <CommunitiesModal
          activeCommunities={activeCommunities}
          handleSelectCommunities={handleSelectCommunities}
          closeModal={handleCloseCommunities}
          communities={communitiesList.filter(item => item.heritage_group_title.toLowerCase() !== "global")}
        />
      )}

      {activeListingModal && (
        <ProfessionsModal
          setActivePracticesParent={setActivePractices}
          setActiveProfessionParent={setActiveProfession}
          closeModal={handleCloseProfessionModal}
          professions={professions}
          practices={practices}
        />
      )}
    </>
  );
};

const ProfessionsModal = ({ closeModal, professions, setActiveProfessionParent, setActivePracticesParent }) => {
  const [searchPracticesValue, setSearchPracticesValue] = useState("");
  const [practices, setPractices] = useState([]);
  const [activeProfession, setActiveProfession] = useState({});
  const [activePractices, setActivePractices] = useState({});

  const handleClickActiveProfession = professionId => {
    const profession = professions.find(item => String(item.profession_id) === String(professionId));

    setActiveProfessionParent(profession);
    setActiveProfession(profession);
    setPractices(profession.children);
    setSearchPracticesValue("");
  };

  const handleClosePracticeModal = () => {
    closeModal();
  };

  const handleSelectPracticeAndCloseModal = practice => {
    setActivePracticesParent(practice);
    setActivePractices({});
    setActiveProfession({});
    closeModal();
  };

  const handleSearchPractices = value => {
    const findPractices = value
      ? activeProfession.children.filter(item => {
          return item.title.toLowerCase().includes(value.toLowerCase());
        })
      : [];

    setActivePracticesParent(findPractices);
    setPractices(findPractices);
    setSearchPracticesValue(value);
  };

  const handleSelectParentPractice = practice => {
    if (practice.children.length) {
      setActivePractices(practice);
    } else {
      handleSelectPracticeAndCloseModal(practice);
    }
  };

  return (
    <Modal closeModal={closeModal} clearAllModals={closeModal} classNameMain={styles.modal}>
      {!activeProfession.title && (
        <>
          <div className={cn(styles.inputWrap, styles.inputWrapModal)}>
            <SearchIcon className={styles.searchIcon} />
            <input
              type="text"
              className={cn(styles.input, styles.inputModal)}
              placeholder="Search by Industry"
              value={searchPracticesValue}
              onChange={event => handleSearchPractices(event.target.value)}
            />
          </div>

          {professions.map(item => {
            return (
              <button
                type="button"
                key={item.title}
                className={styles.categoryItemProfession}
                onClick={() => handleClickActiveProfession(item.profession_id)}
              >
                {item.title}
              </button>
            );
          })}
        </>
      )}

      {activeProfession.title && (
        <>
          {!activePractices.title ? (
            <>
              <div className={styles.heading}>
                <button type="button" className={cn(styles.viewAll, styles.back)} onClick={() => setActiveProfession({})}>
                  <Arrow />
                  Back
                </button>

                <button type="button" className={cn(styles.viewAll, styles.back)} onClick={handleClosePracticeModal}>
                  Select {activeProfession.title}
                </button>
              </div>

              {/*
                {activeCategory === communities.country && (
                  <div className={styles.heading}>
                    <button type="button" className={styles.viewAll} onClick={() => handleSetCategories(communities.all)}>
                      <Arrow />
                      Accounting and Financial
                    </button>
                  </div>
                )}
                */}

              <div className={cn(styles.inputWrap, styles.inputWrapModal)}>
                <SearchIcon className={styles.searchIcon} />
                <input
                  type="text"
                  className={cn(styles.input, styles.inputModal)}
                  placeholder="Search by Practices"
                  value={searchPracticesValue}
                  onChange={event => handleSearchPractices(event.target.value)}
                />
              </div>

              <div className={styles.categories}>
                <div className={cn(styles.category, styles.practicesContent)}>
                  {practices.map(item => {
                    return (
                      <>
                        <button
                          key={item.title}
                          type="button"
                          className={styles.categoryItemPractices}
                          onClick={() => handleSelectParentPractice(item)}
                        >
                          {item.title}
                        </button>
                        {/*
                        {item.children
                          ? item.children.map(itemChildren => (
                              <button key={itemChildren.title} type="button" className={styles.categoryItem}>
                                {itemChildren.title}
                              </button>
                            ))
                          : null}
                          */}
                      </>
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            <div>
              <div className={styles.heading}>
                <button type="button" className={cn(styles.viewAll, styles.back)} onClick={() => setActivePractices({})}>
                  <Arrow />
                  Back
                </button>
              </div>

              <div className={styles.practicesChildContent}>
                {activePractices.children.map(item => {
                  return (
                    <>
                      <button
                        key={item.title}
                        type="button"
                        className={cn(styles.categoryItemPractices, styles.categoryItemPracticesBold)}
                        onClick={() => handleSelectPracticeAndCloseModal(item)}
                      >
                        {item.title}
                      </button>

                      {item.children
                        ? item.children.map(itemChildren => (
                            <>
                              <button
                                key={itemChildren.title}
                                type="button"
                                className={styles.categoryItemPractices}
                                onClick={() => handleSelectPracticeAndCloseModal(itemChildren)}
                              >
                                {itemChildren.title}
                              </button>

                              {itemChildren.children
                                ? itemChildren.children.map(subChildren => (
                                    <>
                                      <button key={subChildren.title} type="button" className={styles.categoryItemPractices}>
                                        {subChildren.title}
                                      </button>
                                    </>
                                  ))
                                : null}
                            </>
                          ))
                        : null}
                    </>
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

const CommunitiesModal = ({ closeModal, communities, handleSelectCommunities, activeCommunities }) => {
  const [activeCommunitiesType, setActiveCommunitiesType] = useState("");
  const [activeCommunityChildren, setActiveCommunityChildren] = useState("");
  const [communitiesList, setCommunitiesList] = useState({});
  const [search, setSearch] = useState("");

  const handleSetCommunities = name => {
    if (name === activeCommunitiesType) {
      setActiveCommunitiesType("");
    } else {
      setActiveCommunitiesType(name);
    }
  };

  const handleChange = event => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (activeCommunities.heritage_title && !activeCommunityChildren.heritage_title) {
      setActiveCommunityChildren(activeCommunities);
    }
  }, []);

  useEffect(() => {
    setCommunitiesList(communities);
  }, [communities]);

  useEffect(() => {
    if (search) {
      const searchCommunities = communities.map(item => {
        const children = item.children.filter(communityItem => {
          return communityItem.heritage_title.toLowerCase().includes(search.toLowerCase());
        });

        return {
          ...item,
          children
        };
      });

      setCommunitiesList(searchCommunities);
    } else {
      setCommunitiesList(communities);
    }
  }, [search]);

  const normalizeCommunities = () => {
    if (Object.values(communitiesList).length) {
      const languages = communitiesList.find(item => item.heritage_group_title.toLowerCase() === "by language")?.children;
      const regions = communitiesList.find(item => item.heritage_group_title.toLowerCase() === "from region").children;
      const cultures = communitiesList.find(item => item.heritage_group_title.toLowerCase() === "by culture").children;
      const religions = communitiesList.find(item => item.heritage_group_title.toLowerCase() === "by religion").children;
      // const globals = communitiesList.find(item => item.heritage_group_title.toLowerCase() === "global")?.children;
      // globals
      return { languages: languages || [], regions, cultures, religions };
    }
    // globals: []
    return { languages: [], regions: [], cultures: [], religions: [] };
  };
  // globals
  const { languages, regions, cultures, religions } = normalizeCommunities();

  return (
    <Modal closeModal={closeModal} clearAllModals={closeModal} classNameMain={styles.modal}>
      {/* {preloaders && <OverlayPreloader overlayClassName="sectionOverlayModal zIndex4" spinnerClassName="zIndex4" />} */}

      <div className={styles.heading}>
        <h6 className={styles.title}>All communities</h6>
        <button type="button" className={styles.viewAll}>
          View All
        </button>
      </div>

      {communitiesList.length ? (
        <div className={styles.tags}>
          {communitiesList.map(item => {
            return (
              <button
                key={item.heritage_group_title}
                type="button"
                className={cn(
                  styles.tagItem,
                  item.heritage_group_title.toLowerCase() === activeCommunitiesType ? styles.tagItemActive : ""
                )}
                onClick={() => handleSetCommunities(item.heritage_group_title.toLowerCase())}
              >
                {item.heritage_group_title}
              </button>
            );
          })}
        </div>
      ) : null}

      <div className={cn(styles.inputWrap, styles.inputWrapModal)}>
        <SearchIcon className={styles.searchIcon} />
        <input
          type="text"
          className={cn(styles.input, styles.inputModal)}
          placeholder="Search location"
          onChange={handleChange}
          value={search}
        />
      </div>

      <div className={styles.categories}>
        {activeCommunitiesType === communitiesTypes.religion || !activeCommunitiesType ? (
          <>
            {regions.map(item => (
              <button
                key={item.heritage_title}
                type="button"
                className={cn(
                  styles.categoryItem,
                  activeCommunityChildren.heritage_title === item.heritage_title ? styles.categoryItemActive : ""
                )}
                onClick={() => handleSelectCommunities(item)}
              >
                {item.heritage_title}
              </button>
            ))}
          </>
        ) : null}

        {activeCommunitiesType === communitiesTypes.languages || !activeCommunitiesType ? (
          <>
            {languages.map(item => (
              <button
                key={item.heritage_title}
                type="button"
                className={cn(
                  styles.categoryItem,
                  activeCommunityChildren.heritage_title === item.heritage_title ? styles.categoryItemActive : ""
                )}
                onClick={() => handleSelectCommunities({ ...item, communityType: "language" })}
              >
                {item.heritage_title}
              </button>
            ))}
          </>
        ) : null}

        {activeCommunitiesType === communitiesTypes.culture || !activeCommunitiesType ? (
          <>
            {cultures.map(item => (
              <button
                key={item.heritage_title}
                type="button"
                className={cn(
                  styles.categoryItem,
                  activeCommunityChildren.heritage_title === item.heritage_title ? styles.categoryItemActive : ""
                )}
                onClick={() => handleSelectCommunities(item)}
              >
                {item.heritage_title}
              </button>
            ))}
          </>
        ) : null}
        {activeCommunitiesType === communitiesTypes.religion || !activeCommunitiesType ? (
          <>
            {religions.map(item => (
              <button
                key={item.heritage_title}
                type="button"
                className={cn(
                  styles.categoryItem,
                  activeCommunityChildren.heritage_title === item.heritage_title ? styles.categoryItemActive : ""
                )}
                onClick={() => handleSelectCommunities(item)}
              >
                {item.heritage_title}
              </button>
            ))}
          </>
        ) : null}
        {/*
        {activeCommunitiesType === communitiesTypes.global || !activeCommunitiesType ? (
          <>
            {globals.map(item => (
              <button
                key={item.heritage_title}
                type="button"
                className={cn(
                  styles.categoryItem,
                  activeCommunityChildren.heritage_title === item.heritage_title ? styles.categoryItemActive : ""
                )}
                onClick={() => handleSelectCommunities(item)}
              >
                {item.heritage_title}
              </button>
            ))}
          </>
        ) : null}
        */}
      </div>
    </Modal>
  );
};

export default MainSearch;
