import { main, auth, signUp } from "../constants/actionTypes";

const defaultState = {
  client: false,
  countries: null,
  error: null,
  errorGlobal: null,
  globalInfo: {
    login: "",
    new_messages: "",
    unread_messages: "",
    unread_notifications: "",
    listings_count: "0",
    referral_requests_count: "0",
    referral_requests_pending_import: "0"
  },
  language: 1,
  isLoggedIn: null,
  notifications: [],
  profileImage: {},
  practices: null,
  preloaders: {
    getGlobalInfo: false,
    verifyEmailCode: null,
    notifications: false
  },
  ppl_status: null,
  states: null,
  verifyEmailCode: {
    verifyCodeSend: null,
    verifyCodeCheck: null,
    verifyCodeStatus: false
  },
  paymentError: {
    isActive: false,
    errorMessage: ""
  },
  isActiveNewListing: false,
  listingModalListingType: "",
  isActiveMobileMenu: false,
  footerInfo: [],
  socialLinks: [],
  communities: []
};

export default function(state = defaultState, action) {
  const { type, payload } = action;

  switch (type) {
    case main.GET_PROFILE_IMAGE: {
      return {
        ...state,
        profileImage: payload
      };
    }

    case main.GET_FOOTER_INFO: {
      return {
        ...state,
        footerInfo: payload.footerInfo,
        socialLinks: payload.socialLinks
      };
    }

    case main.GET_UTILITY_LISTING: {
      return {
        ...state,
        ...payload,
        preloaders: {
          ...state.preloaders,
          getGlobalInfo: false,
          notifications: false
        }
      };
    }

    case main.GET_UTILITY_LISTING_FAIL:
      return {
        ...state,
        error: payload,
        preloaders: {
          ...state.preloaders,
          getGlobalInfo: false,
          notifications: false
        }
      };

    case main.SET_LOADING:
      return {
        ...state,
        preloaders: { ...state.preloaders, [payload.section]: payload.isSectionLoading }
      };

    case main.MARK_UNREAD: {
      return {
        ...state,
        notifications: payload
      };
    }

    case main.MARK_READ: {
      return {
        ...state,
        notifications: payload
      };
    }

    case auth.SET_SUCCESS:
    case auth.LOGIN_SUCCESS:
    case signUp.SET_SUCCESS:
    case main.GET_LOGIN:
    case main.GET_LOGIN_FAIL:
      return {
        ...state,
        // eslint-disable-next-line no-prototype-builtins
        isLoggedIn: payload.hasOwnProperty("ok") ? payload.ok : payload
      };

    case auth.LOGOUT:
      return {
        ...state,
        isLoggedIn: false
      };

    case auth.SET_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: payload
      };

    case main.SEND_EMAIL_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        verifyEmailCode: {
          ...state.verifyEmailCode,
          verifyCodeSend: true
        }
      };

    case main.SEND_EMAIL_VERIFY_CODE_FAIL:
      return {
        ...state,
        verifyEmailCode: {
          ...state.verifyEmailCode,
          verifyCodeSend: false
        }
      };

    case main.CHECK_EMAIL_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        verifyEmailCode: {
          ...state.verifyEmailCode,
          verifyCodeCheck: true
        },
        preloaders: {
          ...state.preloaders,
          verifyEmailCode: false
        }
      };

    case main.CHECK_EMAIL_VERIFY_CODE_FAIL:
      return {
        ...state,
        verifyEmailCode: {
          ...state.verifyEmailCode,
          verifyCodeCheck: false
        },
        preloaders: {
          ...state.preloaders,
          verifyEmailCode: false
        }
      };

    case main.TOGGLE_PAYMENT_ERROR:
      return {
        ...state,
        paymentError: {
          isActive: payload.isActive,
          errorMessage: payload.errorMessage
        }
      };

    case main.TOGGLE_NEW_LISTING:
      return {
        ...state,
        isActiveNewListing: payload.isActive,
        listingModalListingType: payload.listingType
      };

    case main.TOGGLE_MOBILE_MENU:
      return {
        ...state,
        isActiveMobileMenu: payload.isActive
      };

    case main.GET_COMMUNITIES:
      return {
        ...state,
        communities: payload
      };

    default:
      return {
        ...state
      };
  }
}
