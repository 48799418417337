import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isToday } from "date-fns";

import Tabs from "./components/tabs";
import Filter from "../saved/filter";
import Profiles from "../saved/profiles";
import Organizations from "../saved/organizations";
import Events from "../saved/events";
import Jobs from "../saved/jobs";
import FollowingModal from "./components/followingModal";
import QuestionForm from "./components/questionForm";
import QuestionFormSuccess from "./components/questionFormSuccess";
import { ReactComponent as EmptyIcon } from "../../media/icons/new-empty-listing.svg";
import { ReactComponent as SearchIcon } from "../../media/icons/search.svg";
import { ReactComponent as PencilIcon } from "../../media/icons/edit-pen-white.svg";
import { actions as homeActions } from "../../actions/home";
import { OverlayPreloader } from "../common/preloader";
import styles from "./feed.module.css";
import PageNames from "../pageNames";
import { useDocTitle } from "../../helpers/hooks";
import { actions as actionProfile } from "../../actions/account/profile";
import { modalsName } from "../../constants/account";
import { Notification } from "../common/notification";

const Feed = () => {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({ value: "", label: "" });
  const [search, setSearch] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const getMyFeed = useCallback((command, search) => dispatch(homeActions.getMyFeed(command, search)), [dispatch]); // eslint-disable-line
  const { myFeed, preloaders, followings, error } = useSelector(state => state.home);
  const { profile, modals } = useSelector(state => state.account);
  const addToFavorite = useCallback(listingId => dispatch(homeActions.addToFavorite(listingId)), [dispatch]);
  const removeAllError = useCallback(() => dispatch(homeActions.removeAllError()), [dispatch]);
  const removeFavorite = useCallback(listingId => dispatch(homeActions.removeFavorite(listingId)), [dispatch]);
  const getFollowing = useCallback(() => dispatch(homeActions.getFollowing()), [dispatch]);
  const removeFollowing = useCallback(id => dispatch(homeActions.removeFollowing(id)), [dispatch]);
  const toggleActivityOfModal = useCallback((modal, isActive) => dispatch(actionProfile.toggleActivityOfModal(modal, isActive)), [
    dispatch
  ]);
  const clearAllModals = useCallback(() => dispatch(actionProfile.clearAllModals()), [dispatch]);

  const all = { value: "All", label: "all" };
  const profiles = { value: "People", label: "profiles" };
  const organizations = { value: "Organizations", label: "organizations" };
  const jobs = { value: "Jobs", label: "jobs" };
  const events = { value: "Events", label: "events" };

  const filterOptions = [all, profiles, organizations, jobs, events];

  const isAddedToday = useMemo(() => {
    return isToday(new Date(profile.added));
  }, [profile.added]);

  const isShowQuestionForm = !profile.name_f && !profile.name_f && isAddedToday;

  const handleShowModal = () => {
    setIsShowModal(true);
  };

  const handleHideModal = () => {
    setIsShowModal(false);
  };

  const handleChangeTab = value => {
    if (value.label !== filter.label) {
      setFilter(value);

      getMyFeed(value.label);
    }
  };

  const count = useMemo(() => {
    if ("profiles" in myFeed.count) {
      return myFeed.count;
    }

    return 0;
  }, [myFeed.count, filter.label]);

  const handleChange = event => {
    setSearch(event.target.value);

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  const handleCloseFormModal = () => {
    clearAllModals();
  };

  useEffect(() => {
    if (!filter.value) {
      setFilter(all);
    }

    getMyFeed();
    getFollowing();
  }, []);

  useEffect(() => {
    if (isShowQuestionForm) {
      toggleActivityOfModal(modalsName.questionForm, true);
    }
  }, [isShowQuestionForm, profile.name_f, profile.name_l]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isEdited) {
        getMyFeed(filter.label, search);
      }
    }, 750);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [search]);

  useDocTitle("dashboard_myfeed_metatitle");

  return (
    <>
      {modals.questionForm && <QuestionForm closeModal={handleCloseFormModal} />}
      {modals.questionFormSuccess && <QuestionFormSuccess clearAllModals={clearAllModals} />}

      {error.favorites && (
        <Notification error close={removeAllError} time={5000}>
          Oooops. Something went wrong
        </Notification>
      )}

      <div className={styles.wrapper}>
        {preloaders.feed && <OverlayPreloader />}
        <PageNames />

        {isShowModal && (
          <FollowingModal
            preloader={preloaders.removeFollowing}
            followings={followings}
            removeFollowing={removeFollowing}
            onClose={handleHideModal}
          />
        )}
        <Tabs />

        {filter.value !== all.value ||
        !search ||
        myFeed?.list?.profiles?.length ||
        myFeed?.list?.organizations?.length ||
        myFeed?.list?.events?.length ||
        myFeed?.list?.jobs?.length ? (
          <>
            <div className={styles.searchContent}>
              <SearchIcon className={styles.searchIcon} />
              <input type="text" placeholder="Search" className={styles.search} value={search} onChange={handleChange} />
              {search ? (
                <button type="button" className={styles.reset} onClick={() => setSearch("")}>
                  Reset
                </button>
              ) : null}
            </div>

            <Filter filter={filter} setFilter={handleChangeTab} filterOptions={filterOptions} />
          </>
        ) : (
          <div className={styles.emptyContent}>
            <EmptyIcon className={styles.icon} />
          </div>
        )}

        <div className={styles.flex}>
          {followings.length ? (
            <div className={styles.following}>
              <div className={styles.followingHeading}>
                <h6 className={styles.followingTitle}>Following ({followings.length})</h6>
                <button type="button" className={styles.followingEdit} onClick={handleShowModal}>
                  <PencilIcon />
                </button>
              </div>

              <div className={styles.followingList}>
                {followings.map(item => {
                  return (
                    <button key={item.name} type="button" className={styles.followingItem}>
                      {item.name}
                    </button>
                  );
                })}
              </div>
            </div>
          ) : null}

          <div className={styles.content}>
            {(filter.value === profiles.value || filter.value === all.value) && (
              <div className={styles.container}>
                {count.profiles ? (
                  <div className={styles.heading}>
                    <span>People</span>
                    <button type="button" className={styles.viewAll} onClick={() => handleChangeTab(profiles)}>
                      View All
                    </button>
                  </div>
                ) : null}

                <Profiles
                  isExpand
                  onClick={() => handleChangeTab(profiles)}
                  isActive={filter.value === profiles.value}
                  handleClickAll={() => handleChangeTab(all)}
                  count={myFeed?.count?.profiles}
                  list={filter.value === all.value ? myFeed.list.profiles?.slice(0, 3) : myFeed.list.profiles}
                  removeFavorite={removeFavorite}
                  addToFavorite={addToFavorite}
                />
              </div>
            )}

            {(filter.value === organizations.value || filter.value === all.value) && (
              <div className={styles.container}>
                {count.organizations ? (
                  <div className={styles.heading}>
                    <span>Organizations</span>
                    <button type="button" className={styles.viewAll} onClick={() => handleChangeTab(organizations)}>
                      View All
                    </button>
                  </div>
                ) : null}

                <Organizations
                  isExpand
                  onClick={() => handleChangeTab(organizations)}
                  isActive={filter.value === organizations.value}
                  handleClickAll={() => handleChangeTab(all)}
                  count={myFeed?.count?.organizations}
                  list={filter.value === all.value ? myFeed.list.organizations?.slice(0, 3) : myFeed.list.organizations}
                  removeFavorite={removeFavorite}
                  addToFavorite={addToFavorite}
                />
              </div>
            )}

            {(filter.value === events.value || filter.value === all.value) && (
              <div className={styles.container}>
                {count.events ? (
                  <div className={styles.heading}>
                    <span>Events</span>
                    <button type="button" className={styles.viewAll} onClick={() => handleChangeTab(events)}>
                      View All
                    </button>
                  </div>
                ) : null}
                <Events
                  isExpand
                  onClick={() => handleChangeTab(events)}
                  isActive={filter.value === events.value}
                  handleClickAll={() => handleChangeTab(all)}
                  organizations
                  count={myFeed?.count?.events}
                  list={filter.value === all.value ? myFeed.list.events?.slice(0, 3) : myFeed.list.events}
                  removeFavorite={removeFavorite}
                  addToFavorite={addToFavorite}
                />
              </div>
            )}

            {(filter.value === jobs.value || filter.value === all.value) && (
              <div className={styles.container}>
                {count.jobs ? (
                  <div className={styles.heading}>
                    <span>Jobs</span>
                    <button type="button" className={styles.viewAll} onClick={() => handleChangeTab(jobs)}>
                      View All
                    </button>
                  </div>
                ) : null}
                <Jobs
                  isExpand
                  onClick={() => handleChangeTab(jobs)}
                  isActive={filter.value === jobs.value}
                  handleClickAll={() => handleChangeTab(all)}
                  organizations
                  count={myFeed?.count?.jobs}
                  list={filter.value === all.value ? myFeed.list.jobs?.slice(0, 3) : myFeed.list.jobs}
                  removeFavorite={removeFavorite}
                  addToFavorite={addToFavorite}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Feed;
