import { search } from "../constants/actionTypes";

const defaultState = {
  preloaders: {
    search: false
  },
  practices: [],
  professions: []
};

export default function(state = defaultState, action) {
  const { type, payload } = action;
  switch (type) {
    case search.SET_LOADING:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          [payload.section]: payload.isLoading
        }
      };

    case search.GET_PROFESSIONS:
      return {
        ...state,
        professions: payload
      };

    case search.GET_PRACTICES:
      return {
        ...state,
        practices: payload
      };

    case search.GET_PRACTICES_FAIL:
    case search.GET_PROFESSIONS_FAIL:
      return {
        ...state,
        preloaders: defaultState.preloaders
      };

    default:
      return {
        ...state
      };
  }
}
