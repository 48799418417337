/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as SearchIcon } from "../../../../media/icons/search.svg";
import { Modal } from "../../../common/modal";
import { Button } from "../../../common/button";
import Input from "../../../common/input";
import styles from "./questionForm.module.css";
import CalendarWithInputMask from "../../../listingDetail/calendarWithInputMask";
// import { actions } from "../../../../actions/landing";
import { actions as actionProfile } from "../../../../actions/account/profile";
import Checkbox from "../../../common/checkbox";
import Location from "../../../account/location";
import { OverlayPreloader } from "../../../common/preloader";

const QuestionForm = ({ closeModal, formValues = {} }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isActiveCityStateZipDropdown, setIsActiveCityStateZipDropdown] = useState(false);
  const cityStateZipListRef = useRef();
  const cityStateZipInputRef = useRef();
  const [usOrCanada, setUsOrCanada] = useState(true);

  const { profileLocationAutoComplete, preloaders, profile } = useSelector(state => state.account);

  const getProfileLocationAutoComplete = useCallback(term => dispatch(actionProfile.getProfileLocationAutoComplete(term)), [dispatch]);
  const updateContacts = useCallback((data, callback) => dispatch(actionProfile.updateContacts(data, callback, true)), [dispatch]);

  const handleInputClick = () => {
    setIsActiveCityStateZipDropdown(prev => !prev);
  };

  const validate = values => {
    const { name } = values;
    const errors = {};

    const requiredText = t("dashboard_common_required");

    if (!name) {
      errors.name = requiredText;
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: formValues.name || "",
      birth_date: formValues.birth_date || "",
      location: formValues.location || "",
      selectedLocation: formValues.selectedLocation,
      state: formValues.state || "",
      country: formValues.country || "",
      city: formValues.city || "",
      zip: formValues.zip || ""
    },
    onSubmit: values => {
      const { name, birth_date, selectedLocation, state, country, city, zip } = values;

      const [name_f, name_l] = name.split(" ");

      updateContacts(
        {
          name_f,
          name_l,
          birth_date,
          city: usOrCanada ? selectedLocation?.city : city,
          state: usOrCanada ? selectedLocation?.state : state?.value,
          country: usOrCanada ? selectedLocation?.country : country?.value,
          zip: usOrCanada ? selectedLocation?.zip : zip
        },
        false
      );
    },
    validate
  });

  const { values, errors, setFieldValue, handleSubmit } = formik;

  const handleChange = e => {
    setFieldValue(e.target.name, e.target.value);
  };

  const handleChangeDate = date => {
    setFieldValue("birth_date", date);
  };

  const handleSelectDate = date => {
    setFieldValue("birth_date", date);
  };

  const handleChangeSelect = (newValue, actionMeta) => {
    setFieldValue(actionMeta.name, newValue);

    if (actionMeta.name === "country") {
      setFieldValue("state", "");
    }
  };

  const handleClickActiveCityStateZip = current => () => {
    setFieldValue("selectedLocation", current);
    setFieldValue("location", `${current.country}, ${current.state}`);

    setIsActiveCityStateZipDropdown(false);
  };

  const renderCityStateZipList = profileLocationAutoComplete?.map(item => {
    return (
      <button
        type="button"
        className={styles.cityStateZipListItem}
        key={`${item.country}-${item.state}`}
        onClick={handleClickActiveCityStateZip(item)}
      >
        <span className={styles.cityStateZipListItemCountry}>
          {item.city}
          {`${item.city ? `, ` : ""}`} {item.state_title}
        </span>
      </button>
    );
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (values.location) {
        getProfileLocationAutoComplete(values.location);
      }
    }, 1500);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [values.location]); // eslint-disable-line

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        cityStateZipListRef.current &&
        !cityStateZipListRef.current.contains(event.target) &&
        !cityStateZipInputRef.current.contains(event.target)
      ) {
        setIsActiveCityStateZipDropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <Modal classNameMain={styles.modal} closeModal={closeModal} clearAllModals={closeModal}>
      {(preloaders.profileLocationAutoComplete || preloaders.contacts) && <OverlayPreloader />}

      <h2 className={styles.title}>Please answer a few questions.</h2>
      <div className={styles.formContent}>
        <label className={styles.label}>
          What is your full name? <span>*</span>
        </label>
        <Input
          isInvalid={errors.name}
          error={errors.name}
          name="name"
          className={styles.input}
          placeholder=""
          onChange={handleChange}
          value={values.name}
        />
      </div>
      <div className={styles.formContent}>
        <label className={styles.label}>What is your birthdate?</label>

        <CalendarWithInputMask
          name="location"
          error={errors.birth_date}
          isInvalid={errors.birth_date}
          value={values.birth_date ? new Date(values.birth_date) : null}
          onChange={date => handleSelectDate(date)}
          handleChangeInput={handleChangeDate}
          placeholder="MM/DD/YYYY"
          defaultMaxDate={new Date()}
          inputClassName={styles.calendarInput}
          minDate={new Date("1899")}
        />
      </div>
      {usOrCanada ? (
        <div className={styles.formContent}>
          <label className={styles.label}>What is your location?</label>

          <div className={styles.searchContent}>
            <SearchIcon className={styles.searchIcon} />
            <Input
              name="location"
              className={styles.searchInput}
              placeholder="Type city, state or a zip code"
              onChange={handleChange}
              value={values.location}
              onClick={handleInputClick}
              inputRef={cityStateZipInputRef}
            />
          </div>
          {profileLocationAutoComplete?.length && isActiveCityStateZipDropdown ? (
            <div className={styles.cityStateZipList} ref={cityStateZipListRef}>
              {renderCityStateZipList}
            </div>
          ) : null}
        </div>
      ) : (
        <Location className={styles.location} handleChangeSelect={handleChangeSelect} values={values} handleChange={handleChange} />
      )}

      <Checkbox
        checked={usOrCanada}
        className={styles.checkbox}
        id="usOrCanada"
        name="usOrCanada"
        onChange={() => setUsOrCanada(prev => !prev)}
        isNewStyles
        big
      >
        United States or Canada
      </Checkbox>

      <Button className={styles.submit} onClick={handleSubmit}>
        Next
      </Button>
    </Modal>
  );
};

export default QuestionForm;
