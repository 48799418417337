import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cn from "classnames";
import { useTranslation } from "react-i18next";

import SelectInput from "../common/selectInput";
import { actions as teamsActions } from "../../actions/teams";
import styles from "./selectTeam.module.css";

const SelectTeam = ({ isShowText, handleChange, text = "View referrals of", unselectText = "Unselect", className }) => {
  const { member } = useSelector(state => state.teams);
  const dispatch = useDispatch();
  const getTeamsMember = useCallback(() => dispatch(teamsActions.getTeamsMember()), [dispatch]);
  const setCurrentTeam = useCallback(team => dispatch(teamsActions.setCurrentTeam(team)), [dispatch]);
  const emptyState = { label: "Invite Team", value: "teams", isDefaultText: true };
  const history = useHistory();
  const { t } = useTranslation();
  const { currentTeam } = useSelector(state => state.teams);

  const options = member?.length
    ? [
        { label: unselectText, value: "" },
        ...member.map(item => ({
          label: item.name_f ? `${item.name_f} ${item.name_l}` : item.member_name,
          value: item.owner_id
        }))
      ]
    : [emptyState];

  const handleChangeTeam = currentValue => {
    if (currentValue.isDefaultText) {
      history.push("/teams");
    } else {
      setCurrentTeam(currentValue);
      handleChange(currentValue);
    }
  };

  useEffect(() => {
    if (getTeamsMember) {
      getTeamsMember();
    }
  }, []);

  return (
    <div className={cn(styles.flex, isShowText ? styles.flexIndent : "", className)}>
      {isShowText && <span className={styles.text}>{text}</span>}
      <SelectInput
        classNameWrap={cn(styles.selectWrap, !isShowText ? styles.selectWrapWidth : "")}
        className={styles.select}
        name="team"
        placeholder={t("dashboard_common_selectteam")}
        onChange={currentValue => handleChangeTeam(currentValue)}
        options={options}
        value={currentTeam.value ? currentTeam : ""}
      />
    </div>
  );
};

export default SelectTeam;
