import React, { useEffect, useMemo, useState } from "react";
import cn from "classnames";

import { ReactComponent as PinIcon } from "../../../media/icons/pin.svg";
import { ReactComponent as CalendarIcon } from "../../../media/icons/calendar.svg";
import { ReactComponent as TicketIcon } from "../../../media/icons/simpleTicket.svg";
import { Button } from "../../common/button";
import { ReactComponent as SaveIcon } from "../../../media/icons/heart.svg";
// import { ReactComponent as ShareIcon } from "../../../media/icons/share.svg";
import styles from "./eventsListing.module.css";

const EventsListing = ({ list, isExpand, addToFavorite, removeFavorite }) => {
  return (
    <div className={cn(styles.flex, isExpand ? styles.flexExpand : "")}>
      {list.map(item => {
        return <Event key={item.title} item={item} isExpand={isExpand} addToFavorite={addToFavorite} removeFavorite={removeFavorite} />;
      })}
    </div>
  );
};

const Event = ({ isExpand, item, addToFavorite, removeFavorite }) => {
  const [isFavorite, setIsFavorite] = useState(false);

  const toggleFavorite = listingId => {
    if (isFavorite) {
      removeFavorite(listingId).then(res => {
        if (res) {
          setIsFavorite(false);
        }
      });
    } else {
      addToFavorite(listingId).then(res => {
        if (res) {
          setIsFavorite(true);
        }
      });
    }
  };

  useEffect(() => {
    if (item.bookmarked === "true") {
      setIsFavorite(true);
    }
  }, []);

  const location = useMemo(() => {
    if (item.lawfirms?.length) {
      const { state, city, openNow } = item.lawfirms[0];
      return { address: `${city}, ${state}`, openNow };
    }

    return "";
  }, [item.lawfirms?.length]);

  const ticket = useMemo(() => {
    if (item.event_tickets?.length) {
      return item.event_tickets[0];
    }

    return {};
  }, [item.lawfirms?.length]);

  return (
    <div className={cn(styles.tile, isExpand ? styles.tileExpand : "")} key={item.title}>
      <div className={styles.favorites}>
        <Button
          className={cn(styles.favoritesButton, isFavorite ? styles.favoritesButtonActive : "")}
          onClick={() => toggleFavorite(item.listing_id)}
        >
          <SaveIcon />
        </Button>
        {/*
        <Button className={styles.favoritesButton}>
          <ShareIcon />
        </Button>
        */}
      </div>

      <div className={isExpand ? styles.expandContainer : ""}>
        <div className={styles.tileImage}>
          <img src={item.cover_image_url} alt="Events banner" />
        </div>
        <div className={styles.titleContent}>
          <h6 className={styles.tileTitle}>{item.attorney}</h6>
          <p className={styles.tileDescription}>{item.description}</p>
          <div className={styles.titleFlex}>
            {location.openNow ? (
              <span className={styles.titleDetail}>
                <CalendarIcon />
                {location.openNow}
              </span>
            ) : null}
            <span className={styles.titleDetail}>
              <PinIcon /> {location.address}
            </span>

            <span className={styles.titleDetail}>
              <TicketIcon /> {`${ticket.currency} ${ticket.price}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsListing;
