import axios from "axios";

import { search } from "../constants/actionTypes";

export const actions = {
  setLoading(section, isLoading) {
    return {
      type: search.SET_LOADING,
      payload: { section, isLoading }
    };
  },

  getProfessions() {
    return dispatch => {
      dispatch(this.setLoading("search", true));
      axios({
        method: "get",
        responseType: "json",
        url: "/members/api-v2/professions"
      })
        .then(res => {
          dispatch({
            type: search.GET_PROFESSIONS,
            payload: res.data.data
          });
        })
        .catch(() => {
          dispatch({
            type: search.GET_PROFESSIONS_FAIL
          });
        });
    };
  },

  getProfessionsTree() {
    return dispatch => {
      dispatch(this.setLoading("search", true));
      axios({
        method: "get",
        responseType: "json",
        url: "/members/api-v2/professions?command=tree"
      })
        .then(res => {
          dispatch({
            type: search.GET_PROFESSIONS,
            payload: res.data.data
          });
        })
        .catch(() => {
          dispatch({
            type: search.GET_PROFESSIONS_FAIL
          });
        });
    };
  },

  getPractices() {
    return dispatch => {
      dispatch(this.setLoading("search", true));
      axios({
        method: "get",
        responseType: "json",
        url: "/members/api-v2/practice-areas?limit=all"
      })
        .then(res => {
          dispatch({
            type: search.GET_PRACTICES,
            payload: res.data.data
          });
        })
        .catch(() => {
          dispatch({
            type: search.GET_PRACTICES_FAIL
          });
        });
    };
  }
};
