import React from "react";
import cn from "classnames";
import format from "date-fns/format";

import styles from "./info.module.css";
import emptyState from "../../../../media/icons/payment-history-empty.svg";
import { ReactComponent as ArrowIcon } from "../../../../media/icons/thinArrow.svg";

const Info = ({ buttonText, list, isOwner, handleAccess, handleRemove, handleAccept, emptyText, isDisabledActions }) => {
  const renderPaymentHistory = () => {
    return list.map(item => (
      <InfoItem
        handleAccess={() => handleAccess(item)}
        handleRemove={() => handleRemove(item)}
        handleAccept={() => handleAccept(item)}
        name={item.name_f || item.name_l ? `${item.name_f} ${item.name_l}` : `${item.member_name}`}
        key={`${item.name_f}-${item.name_l}-${item.status_tm}`}
        since={item.status_tm}
        status={item.status}
        isOwner={isOwner}
        buttonText={buttonText}
        isDisabledActions={isDisabledActions}
      />
    ));
  };

  return (
    <div className={styles.billings}>
      {!list ||
        (list.length === 0 && (
          <section className={styles.emptyState}>
            <img src={emptyState} alt="no prior payments" />
            <p>{emptyText}</p>
          </section>
        ))}

      {list && list.length !== 0 && (
        <>
          <div className={styles.payment}>
            <div className={styles.tableHead}>
              <div className={cn(styles.tableCell, styles.tableCellHead)}>Name</div>
              <div className={cn(styles.tableCell, styles.tableCellHead)}>Since</div>
              <div className={cn(styles.tableCell, styles.tableCellHead)}>Status</div>
              <div className={cn(styles.tableCell, styles.tableCellHead)}>Access</div>
            </div>
            {renderPaymentHistory()}
          </div>
        </>
      )}
    </div>
  );
};

const InfoItem = ({ name, since, status, buttonText, handleAccess, handleRemove, handleAccept, isOwner, isDisabledActions }) => {
  const isStatusSent = !isOwner && String(status) === "0";

  const renderStatus = () => {
    if (String(status) === "0") {
      return (
        <span className={styles.flex}>
          <span className={cn(styles.cycle, styles.cycleYellow)} />
          Sent
        </span>
      );
    }
    if (String(status) === "1") {
      return (
        <span className={styles.flex}>
          <span className={cn(styles.cycle, styles.cycleGreen)} />
          Accepted
        </span>
      );
    }
    if (String(status) === "2") {
      return (
        <span className={styles.flex}>
          <span className={cn(styles.cycle, styles.cycleRed)} />
          Expired
        </span>
      );
    }
    if (String(status) === "3") {
      return (
        <span className={styles.flex}>
          <span className={cn(styles.cycle, styles.cycleRed)} />
          Rejected
        </span>
      );
    }
    return "";
  };

  return (
    <div className={styles.tableRow}>
      <div className={cn(styles.tableCell, styles.tableBorder)}>
        <span className={styles.itemTitle}>Name</span>
        {name}
      </div>

      <div className={cn(styles.tableCell, styles.tableBorder)}>
        <span className={styles.itemTitle}>Since</span>
        {format(new Date(since), "MM/dd/yyyy")}
      </div>
      <div className={cn(styles.tableCell, styles.paymentAmount, styles.tableBorder)}>
        <span className={styles.itemTitle}>Status</span>

        <div>
          <span>{renderStatus()}</span>
          {isStatusSent && <span>Invited</span>}
        </div>
      </div>
      <div className={cn(styles.tableCell, styles.tableBorder)}>
        <span className={styles.itemTitle}>Access</span>

        <div>
          {isStatusSent ? (
            <button type="button" className={styles.active} onClick={handleAccept} disabled={isDisabledActions}>
              Review
              <ArrowIcon />
            </button>
          ) : (
            <>
              <button type="button" className={styles.active} onClick={handleAccess} disabled={isDisabledActions}>
                {buttonText}
                <ArrowIcon />
              </button>
              <button type="button" className={styles.remove} onClick={handleRemove} disabled={isDisabledActions}>
                Remove Access
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Info;
